@import '../variables';
.slider-container {
  width: 100%;
  height: 350px;
  background-color: #fff;
  padding: 10px;

  .card {
    width: 500px; /* Ширина блока */
    height: 300px; /* Высота блока */
    background-size: cover;
    position: relative;
    z-index: 3;

    img {
      width: 100%; /* Ширина блока */
      height: 350px; /* Высота блока */
      object-fit: cover;
    }
    &::after {
      position: absolute;
      content: '';
      height: 350px;
      background: linear-gradient(45deg, rgb(136, 19, 140), blue);
      inset: 0;
      opacity: 0.1;
      z-index: 2;
    }
    p {
      position: absolute;
      font-size: 46px;
      font-weight: 800;
      font-style: italic;
      color: #fff;

      z-index: 3;
      bottom: 20px;
      right: 50px;

      @media (max-width: 820px) {
        font-size: 40px;
        right: 10px;
        bottom: 10px;
      }

      @media (max-width: 645px) {
        font-size: 35px;
        right: 5px;
        bottom: 5px;
      }

      span {
        color: red;
        margin-left: 10px;

        svg {
          padding-top: 15px;
          width: 0.7em;
        }
      }

      :nth-child(2) {
        color: aqua;
      }
      :nth-child(3) {
        color: yellow;
      }
    }
  }
}
