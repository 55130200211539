@import './variables';
@import './libs/normalize';

@import './components/all';

body {
  background-color: $background;
  #root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
  }
}

.wrapper {
  width: calc(100vw - 100px);
  height: 100%;
  background-color: #f5f5f5;
  margin-top: 30px;
  margin: 0px auto;
  border-radius: 10px;
  max-width: 1400px;

  @media (max-width: 760px) {
    width: calc(100vw - 50px);
  }
  @media (max-width: 645px) {
    width: 100vw;
    margin: 0 auto;
    border-radius: 0;
  }
}

.content {
  &__error-info {
    margin: 100px auto;
    width: 500px;
    text-align: center;

    h2 {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      font-size: 20px;
    }

    @media (max-width: 645px) {
      margin: 0 auto;
      width: 300px;
      text-align: center;
      height: 100vh;

      h2 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .content {
    &__promo {
      padding: 15px 0;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      > div:first-child {
        display: flex;
        justify-content: space-around;
        width: 100%;

        div {
          border-radius: 5px;
          padding: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            color: #0b8eb3;
            width: 50px;
            height: 50px;
          }

          p {
            margin-top: 5px;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      > div:last-child {
        background-color: #64c6e1;
        color: #fff;
        margin-top: 10px;
        font-weight: 800;
        font-size: 20px;
        padding: 10px;

        width: 100%;
      }
    }
  }

  &__title {
    font-weight: 800;
    font-size: 38px;

    margin-bottom: 10px;
    padding: 32px;
    text-align: center;
    font-style: italic;
    background-color: #f5f5f5;

    img {
      width: 40px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @media (max-width: 645px) {
      font-size: 30px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 11px;
    align-items: start;
    background-color: #fff;
    padding: 30px 10px;
    // min-height: 1000px;

    @media (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1060px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__top {
    width: 100%;
    margin: 0 auto;
    margin-top: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 760px) {
      margin-top: 135px;
    }

    @media (max-width: 820px) {
      flex-direction: column-reverse;
    }
  }
  &__description {
    width: 100%;
    margin: 0 auto;
    h1,
    h2,
    h3,
    h4 {
      font-size: 1.2rem;
      margin-bottom: 10px;
      text-align: center;
      background-color: #f5f5f5;
    }
    p {
      text-align: justify;
      padding: 10px;
    }
  }
}

.container {
  width: $container-width;
  margin: 0 auto;
  padding-top: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  // @media (max-width: 760px) {
  //   img {
  //     max-width: 300px;
  //   }
  // }

  &--cart {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 10px;
    margin: 0 auto;
    width: $container-width;
    padding: 20px 30px;
    background-color: #fff;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column-reverse;
    }

    .pay-btn {
      width: 60%;
      margin: 0 auto;
      background-color: #4caf50;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 60px;

      &:hover {
        background-color: #45a049;
      }
    }

    @media (max-width: 645px) {
      padding: 10px;
      padding-top: 35px;
    }
    .content__title {
      margin: 0;
      background-color: #fff;
    }
  }

  &--about,
  &--guarantee,
  &--contract-ofer,
  &--contact-us {
    max-width: 820px;
    margin: 60px auto;
    padding-bottom: 30px;
    padding: 0 30px;
    min-height: 100vh;

    h3 {
      font-size: 22px;
      margin-bottom: 14px;
    }
    p {
      margin-bottom: 15px;
      text-align: justify;
    }
  }
  &--about {
    svg {
      color: #0b8eb3;
      margin-right: 5px;
    }
  }
  &--contact-us {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;

    .contacts-info,
    .contacts-schedule {
      grid-column: span 1;
    }

    .contacts-info {
      display: flex;
      flex-direction: column;

      .phoneNumber {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: normal;
        // justify-content: center;
        justify-items: center;

        .phoneIcon {
          color: #0b8eb3;
          width: 1.7rem;
          height: 1.7rem;
          margin-right: 5px;
          &:hover {
            color: darken(#0b8eb3, 10%);
          }
        }

        p {
          font-size: 18px;
          text-transform: uppercase;
          width: max-content;
          text-align: center;
          align-items: center;
          &:hover {
            color: darken(#0b8eb3, 8%);
          }
        }
      }

      .viber,
      .telegram {
        display: flex;
        align-items: normal;
        .viberIcon {
          color: #784f97;
          width: 1.5rem;
          height: 1.5rem;
          &:hover {
            color: darken(#784f97, 15%);
          }
        }
        .telegramIcon {
          color: #259bd8;
          width: 1.5rem;
          height: 1.5rem;
          &:hover {
            color: darken(#259bd8, 15%);
          }
        }
        p {
          margin-left: 7px;
          font-style: oblique;
          &:hover {
            color: darken(#784f97, 15%);
          }
        }
      }
      .telegram {
        p {
          &:hover {
            color: darken(#259bd8, 15%);
          }
        }
      }
    }

    .contacts-map {
      grid-column: span 2;

      iframe {
        width: 100%;
      }
    }
  }
}

.cart {
  margin-left: 15px;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(165, 163, 163);

    .cart-img {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 18px;

      svg {
        position: relative;
        top: -2px;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-bottom: none;
        path {
          stroke: $black;
          stroke-width: 1.9;
        }
      }
      h3 {
        font-size: 20px;
        font-style: italic;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 7px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  .content__items {
    display: block;
  }

  &__item {
    display: flex;
    width: 100%;
    border-top: 1px solid $gray-line;
    padding-top: 30px;
    margin-top: 30px;

    &-img {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 10%;

      img {
        width: 80px;
        height: 80px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40%;

      h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.01em;
        padding-left: 15px;
      }

      p {
        font-size: 18px;
        color: #8d8d8d;
      }
    }

    &-count {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      width: 13%;
      margin: auto 5px;

      @media (max-width: 1200px) {
        flex-direction: row;
      }

      @media (max-width: 890px) {
        flex-direction: column-reverse;
      }

      &-minus {
        svg {
          path:first-of-type {
            display: none;
          }
        }

        &:disabled {
          opacity: 0.4;
          border-color: #787878;

          &:active {
            transform: none;
          }

          &:hover {
            background-color: transparent;

            svg path {
              fill: #787878;
            }
          }

          svg path {
            fill: #787878;
          }
        }
      }

      b {
        font-size: 22px;
        padding: 0 5px;
      }
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;

      b {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.01em;
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 4%;

      .button {
        border-color: darken($color: $gray-line, $amount: 10);
      }

      svg {
        transform: rotate(45deg);

        path {
          fill: darken($color: $gray-line, $amount: 15);
        }
      }

      .button {
        svg {
          width: 11.5px;
          height: 11.5px;
          position: relative;
        }
        &:hover,
        &:active {
          border-color: darken($color: $gray-line, $amount: 80);
          background-color: darken($color: $gray-line, $amount: 80);
        }
      }
    }
  }

  &__bottom {
    margin: 40px 0;

    @media (max-width: 1200px) {
      flex-direction: row;
    }

    @media (max-width: 645px) {
      flex-direction: column;
      align-items: center;
    }

    &-details {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 22px;

        &:last-of-type {
          b {
            color: $blue;
          }
        }
      }
    }
  }
  .go-back-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 80px;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 10px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  &--empty {
    margin: 80px auto;
    width: 620px;
    text-align: center;
    @media (max-width: 645px) {
      margin-top: 30px !important;
    }
    .go-back-btn {
      justify-content: center;
    }

    h2 {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    p {
      font-size: 20px;
      line-height: 145.4%;
      letter-spacing: 0.01em;
      color: #777777;
    }

    icon {
      position: relative;
      top: 2px;
    }

    img {
      display: block;
      width: 300px;
      margin: 45px auto 60px;
    }

    .button--black {
      padding: 12px 0 14px;
      width: 230px;
      margin: 0 auto;
      font-weight: 600;
      font-size: 18px;
    }
  }

  @media (max-width: 645px) {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .content__title {
      display: flex;
      align-items: center;
      font-size: 20px;

      svg {
        position: relative;
        top: -2px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        path {
          stroke: $black;
          stroke-width: 1.9;
        }
      }
    }

    &__clear {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include noselect();

      span {
        display: inline-block;
        margin-left: 4px;
        color: #b6b6b6;
        font-size: 14px;
      }

      span,
      svg,
      path {
        transition: all $duration ease-in-out;
      }

      &:hover {
        svg {
          path {
            stroke: darken($color: #b6b6b6, $amount: 50);
          }
        }
        span {
          color: darken($color: #b6b6b6, $amount: 50);
        }
      }
    }

    .content__items {
      display: block;
      padding: 5px;
    }

    &__item {
      display: flex;
      width: 100%;
      border-top: 1px solid $gray-line;
      padding-top: 20px;
      margin-top: 20px;

      &-img {
        display: flex;
        align-items: center;
        margin-right: 15px;
        width: 20%;

        img {
          width: 50px;
          height: 50px;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;

        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 27px;
          letter-spacing: 0.01em;
        }

        p {
          font-size: 14px;
          color: #8d8d8d;
        }
      }

      &-count {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin: auto 5px;
        width: 20%;

        &-minus {
          svg {
            path:first-of-type {
              display: none;
            }
          }

          &:disabled {
            opacity: 0.4;
            border-color: #787878;

            &:active {
              transform: none;
            }

            &:hover {
              background-color: transparent;

              svg path {
                fill: #787878;
              }
            }

            svg path {
              fill: #787878;
            }
          }
        }

        b {
          font-size: 18px;
        }
      }

      &-price {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto 5px;
        text-align: center;
        width: 45%;

        b {
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.01em;
        }
      }

      &-remove {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 10%;

        .button {
          border-color: darken($color: $gray-line, $amount: 10);
        }

        svg {
          transform: rotate(45deg);

          path {
            fill: darken($color: $gray-line, $amount: 15);
          }
        }

        .button {
          svg {
            width: 11.5px;
            height: 11.5px;
            position: relative;
          }
          &:hover,
          &:active {
            border-color: darken($color: $gray-line, $amount: 80);
            background-color: darken($color: $gray-line, $amount: 80);
          }
        }
      }
    }

    &__bottom {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;

      &-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          font-size: 18px;

          &:last-of-type {
            b {
              color: $blue;
            }
          }
        }
      }

      &-buttons {
        display: flex;
        justify-content: space-between;

        .go-back-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          margin-top: 30px;

          border-color: darken($color: $gray-line, $amount: 10);

          span {
            color: darken($color: $gray-line, $amount: 20);
            font-weight: 300;
          }

          &:hover {
            background-color: darken($color: $gray-line, $amount: 90);
            border-color: darken($color: $gray-line, $amount: 90);

            span {
              color: $gray-line;
            }
          }

          svg {
            margin-right: 12px;
            path {
              fill: transparent;
              stroke-width: 2;
            }
          }
        }

        .pay-btn {
          font-size: 16px;
          font-weight: 900;
          width: 150px;
          padding: 16px;
        }
      }
    }

    &--empty {
      margin: 0px auto;
      max-width: 300px;
      text-align: center;
      height: 100vh;

      h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
        line-height: 145.4%;
        letter-spacing: 0.01em;
        color: #777777;
      }

      icon {
        position: relative;
        top: 2px;
      }

      img {
        display: block;
        width: 200px;
        margin: 35px auto 40px;
      }

      .button--black {
        padding: 10px 0 12px;
        width: 170px;
        margin: 0 auto;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.scroll-to-top-container {
  position: fixed;
  bottom: 130px; /* Чтобы было расстояние между кнопками и скроллом */
  right: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  z-index: 1000;

  @media (max-width: 820px) {
    right: 5px;
    bottom: 100px;
    gap: 1.2rem;
  }

  .viber,
  .telegram,
  .instagram {
    .viberIcon {
      fill: #784f97;
      width: 2.5rem;
      height: 2.5rem;
      &:hover {
        color: darken(#784f97, 15%);
      }
    }

    .telegramIcon {
      fill: #259bd8;
      width: 2.5rem;
      height: 2.5rem;
      &:hover {
        color: darken(#259bd8, 15%);
      }
    }
    img {
      width: 2.5rem;
      height: 2.5rem;
      &:hover {
        color: darken(#259bd8, 15%);
      }
    }
  }

  .scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    border: solid 0.5px;
    cursor: pointer;
    transition:
      opacity 0.3s,
      visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    font-size: 24px;

    @media (max-width: 820px) {
      right: 5px;
      width: 45px;
      height: 45px;
    }
  }

  .scroll-to-top.visible {
    opacity: 1;
    visibility: visible;

    svg {
      text-align: center;
    }
  }

  .scroll-to-top:hover {
    background-color: #0dadd9;
    color: #fff;
  }
}
