@import '../variables';

.button {
  display: inline-block;
  background-color: $blue;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  transition:
    background-color $duration ease-in-out,
    border-color $duration ease-in-out;
  border: 1px solid transparent;
  @include noselect();

  &,
  span {
    color: #fff;
  }

  i,
  span,
  path,
  svg {
    transition: all $duration ease-in-out;
  }

  &:hover {
    background-color: darken($blue, 8%);
  }

  &:active {
    background-color: darken($blue, 12%);
    transform: translateY(1px);
  }

  &--circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    padding: 0;
    border-width: 2px;
  }

  &--black {
    background-color: $black;

    &:hover,
    &:active {
      background-color: lighten($color: $black, $amount: 10);
    }
  }

  &--outline {
    background-color: #fff;
    border-color: $blue;

    &,
    span {
      color: $blue;
    }

    svg {
      path {
        fill: $blue;
      }
    }

    &:hover {
      background-color: $blue;

      &,
      span {
        color: #fff;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    &:active {
      background-color: darken($blue, 8%);
    }
  }

  &__delimiter {
    width: 1px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.25);
    margin-left: 14px;
    margin-right: 14px;

    @media (max-width: 645px) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &--add {
    svg {
      margin-right: 2px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    span {
      font-weight: 600;
      font-size: 16px;
    }

    &:hover {
      i {
        background-color: #fff;
        color: $blue;
      }
    }

    i {
      display: inline-block;
      border-radius: 30px;
      background-color: $blue;
      color: #fff;
      font-weight: 600;
      width: 22px;
      height: 22px;
      font-style: normal;
      font-size: 13px;
      line-height: 22px;
      position: relative;
      top: -1px;
      left: 3px;
    }
  }
  &--back {
  }

  &--cart {
    display: flex;
    align-items: center;
    line-height: 23px;
    padding: 6px 20px;
    border-radius: 100px;
    background: linear-gradient(45deg, #ff357a, #fff172);
    border: none;
    &:hover {
      background: linear-gradient(45deg, #f4256a, #fbea56);
    }

    @media (max-width: 645px) {
      padding: 4px;
    }

    svg {
      margin-right: 3px;
      margin-bottom: 1px;

      @media (max-width: 645px) {
        min-width: 12px;
        min-height: 12px;
      }
    }

    span {
      font-weight: 600;
      font-size: 16px;

      @media (max-width: 645px) {
        font-weight: 600;
        font-size: 10px;
        margin-left: 4px;
        white-space: nowrap;
      }
    }
  }
}
