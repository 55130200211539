@import '../variables';

.categories {
  background-color: #f5f5f5;
  width: $container-width;
  margin: 0 auto;
  ul {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%;
      border-left: 1px solid #cecece;

      background-color: #0dadd9;
      padding: 10px;

      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.1s ease-in-out;
      @include noselect();

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 3.5rem;
        font-size: 16px;
        text-align: center;
        color: #fff;
      }

      &:hover {
        background-color: lighten(#0dadd9, 5%);
        color: #fff;
      }

      &.active {
        background-color: lighten(#0dadd9, 20%);
        color: #fff;
      }
    }
  }

  @media (max-width: 1260px) {
    overflow: auto;

    ul {
   
      max-height: 100%;
      padding-bottom: 15px;

      a {
        padding: 8px;
        border-radius: 30px;
        margin-right: 7px;
        background-color: white;
        font-weight: bold;
        border: 0;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out;
        @include noselect();

        p {
          color: #0b8eb3;
        }

        &:hover {
          background-color: lighten(#0dadd9, 5%);
          p {
            color: #fff;
          }
        }

        &.active {
          background-color: lighten(#0dadd9, 20%);
          p {
            color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 820px) {
    margin-top: 30px;
  }

  @media (max-width: 760px) {
    margin-top: 45px;
  }
}
