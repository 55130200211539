@import '../variables';

.footer {
  //  align-self: flex-end;
  bottom: 0;
  //  position: fixed;
  background-color: #576989;
  min-width: 100%;

  @media (max-width: 645px) {
    padding: 10px 10px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    background-color: #576989;
    box-shadow: none;
    margin: 0px auto;
    max-width: 1400px;

    @media (max-width: 645px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 10px;
    }

    &__logo {
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media (max-width: 645px) {
        flex-direction: column;
        align-items: center;
        grid-row: 2;
        grid-column: 1 / span 2;
      }
      :nth-child(1) {
        width: 100px;
        text-align: center;
        margin-right: 10px;
      }
      div {
        margin-top: 5px;
        color: #fff;
        font-size: 24px;
        letter-spacing: 1%;
        text-transform: uppercase;
        font-style: oblique;
        font-weight: 900;
        width: max-content;

        @media (max-width: 645px) {
          font-size: 16px;
        }
      }
      p {
        color: #fff;
        margin-top: 20px;
        font-size: 14px;
        text-align: center;
      }
    }

    svg,
    img {
      width: 80px;
    }

    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      @media (max-width: 820px) {
        align-items: normal;
      }

      @media (max-width: 645px) {
        grid-row: 1;
        grid-column: 1;
        flex-direction: column !important;
        align-items: flex-start;
      }
      a {
        color: #fff;
        font-size: 18px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .contacts {
    grid-column: 2 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: auto auto;
    grid-gap: 45px;

    @media (max-width: 645px) {
      margin-top: 15px;

      .viber {
        grid-row: 2;
        grid-column: 1 / span 1;
      }
      .telegram {
        grid-row: 2;
        grid-column: 2 / span 2;
      }
      .instagram {
        grid-row: 2;
        grid-column: 3 / span 3;
      }
    }

    .phoneNumber {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-row: 1;
      grid-column: 2 / span 3;
      text-wrap: nowrap;

      @media (max-width: 645px) {
        grid-column: 1 / span 3;
      }

      .phoneIcon {
        color: #fff;
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 5px;
        @media (max-width: 645px) {
          display: none;
        }

        &:hover {
          color: darken(#fff, 10%);
        }
      }

      p {
        color: #fff;
        font-size: 1.7rem;
        letter-spacing: 1%;
        text-transform: uppercase;
        font-style: oblique;
        font-weight: 800;
        width: max-content;
        text-align: center;

        &:hover {
          color: darken(#fff, 8%);
        }
      }
    }

    .viber {
      grid-column: 1;
      grid-row: 2;

      @media (max-width: 645px) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    .telegram {
      grid-column: 2;
      grid-row: 2;
    }

    .instagram {
      grid-column: 3;
      grid-row: 2;
    }

    .viber,
    .telegram,
    .instagram {
      display: flex;
      align-items: center;
      .viberIcon {
        color: #fff;
        width: 1.5rem;
        height: 1.5rem;
        &:hover {
          color: darken(#fff, 15%);
        }
      }
      .telegramIcon {
        color: #fff;
        width: 1.5rem;
        height: 1.5rem;
        &:hover {
          color: darken(#fff, 15%);
        }
      }
      .instagramIcon {
        fill: white;
        width: 1.5rem;
        height: 1.5rem;
        &:hover {
          fill: darken(#fff, 15%);
        }
      }
      p {
        color: #fff;
        font-style: oblique;
        margin-left: 5px;
        font-size: 16px;
        @media (max-width: 645px) {
          display: none;
        }
        &:hover {
          color: darken(#fff, 15%);
        }
      }
    }
    .telegram {
      p {
        color: #fff;
        &:hover {
          color: darken(#fff, 15%);
        }
      }
    }
  }

  @media (max-width: 1030px) {
    .container {
      svg:last-child {
        display: none;
      }
    }
  }

  @media (max-width: 820px) {
    .container {
      padding: 5px 10px;

      &__logo {
        display: flex;
        padding: 5px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        grid-column: 1 / span 2;

        :nth-child(1) {
          width: 70px;
        }
        div {
          margin-top: 5px;
          color: #fff;
          font-size: 18px;
          width: max-content;

          @media (max-width: 645px) {
            font-size: 16px;
          }
        }
        p {
          color: #fff;
          margin-top: 20px;
          font-size: 10px;
          text-align: center;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media (max-width: 645px) {
          flex-direction: row;
        }
        a {
          font-size: 14px;
        }
      }
    }

    .contacts {
      .phoneNumber {
        p {
          font-size: 1.3rem;
        }
      }

      .viber,
      .telegram,
      .instagram {
        .viberIcon {
          width: 1.1rem;
          height: 1.1rem;
        }
        .telegramIcon {
          width: 1.1rem;
          height: 1.1rem;
        }
        .instagramIcon {
          width: 1.1rem;
          height: 1.1rem;
        }
        p {
          color: #fff;
          font-style: oblique;
          margin-left: 5px;
          font-size: 16px;
          &:hover {
            color: darken(#fff, 15%);
          }
        }
      }
      .telegram {
        p {
          color: #fff;
          &:hover {
            color: darken(#fff, 15%);
          }
        }
      }
    }
  }
}
