@import '../variables';

.product-block {
  width: 280px;
  text-align: center;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3%;

    @media (max-width: 768px) {
      height: 200px;
    }

    img {
      max-width: 100%;
      height: 100%;
      transition: transform 0.3s ease;
      margin: 0 auto;
    }

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      img {
        transform: scale(1.05);
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 1%;
    margin: 10px;
    width: 250px;
    height: 30px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
      width: 100%;
    }
  }

  &__description {
    display: flex;
    background-color: #f3f3f3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    flex-direction: column;
    padding: 6px;
    text-align: justify;
    margin-top: 5px;
    min-height: 70px;

    @media (max-width: 768px) {
      padding: 4px;
      margin-top: 4px;
    }

    p {
      font-size: 13px;
      font-style: italic;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    margin-top: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
    }
  }

  &__price {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.015em;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}
