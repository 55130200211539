@import '../variables';
.content {
  .container {
    @media (max-width: 768px) {
      width: 100%;
    }
    .container-product {
      width: 500px;
      margin-bottom: 35px;
      padding: 20px;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        grid-template-columns: 1fr;
      }

      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 380px;
        height: 300px;
        position: relative;
        overflow: hidden;

        @media (max-width: 768px) {
          width: 100%;
          height: 200px;
        }

        img {
          max-width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }

      .title-container {
        width: 280px;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          width: 100%;
        }

        .product-info {
          display: flex;
          flex-direction: column;
          padding: 6px;
          min-height: 70px;

          @media (max-width: 768px) {
            padding: 4px;
            margin-top: 4px;
          }

          p {
            font-size: 18px;
            font-weight: normal;
            margin-top: 10px;
            font-style: initial;
            text-align: justify;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
          span {
            font-weight: 800;
            font-size: 18px;

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
      }

      button {
        grid-column: 1 / -1;
        margin: 15px auto;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .go-back-btn {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 10px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }
}
